import getGroup from '@/api/vk/getGroup'
import getGroupVideosStatsForPeriod from '@/api/vk/getGroupVideosStatsForPeriod'
import getGroupVideosUploadsGraph from '@/api/vk/getGroupVideosUploadsGraph'
import getGroupVideosViewsGraph from '@/api/vk/getGroupVideosViewsGraph'
import getGroupVideos from '@/api/vk/getGroupVideos'
import getGroupPostsStatsForPeriod from '@/api/vk/getGroupPostsStatsForPeriod'
import getGroupPostsPublishGraph from '@/api/vk/getGroupPostsPublishGraph'
import getGroupPostsTotalReachGraph from '@/api/vk/getGroupPostsTotalReachGraph'
import getGroupPosts from '@/api/vk/getGroupPosts'
import syncGroup from '@/api/vk/syncGroup'
import getGroupPostReposts from '@/api/vk/getGroupPostReposts'

export default {
  namespaced: true,

  state: {
    group: null,
    groupVideosStats: {
      count: 0,
      views: 0,
      averageViews: 0,
      likes: 0,
      reposts: 0,
      comments: 0,
      duration: 0,
      averageDuration: 0,
    },
    groupPostsStats: {
      count: 0,
      likes: 0,
      reposts: 0,
      comments: 0,
    },
    groupVideosUploadsGraph: [],
    groupVideosViewsGraph: [],
    groupPostsPublishGraph: [],
    groupPostsTotalReachGraph: [],
    videos: {
      items: [],
      paging: {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
      },
    },
    posts: {
      items: [],
      paging: {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
      },
    },
    reposts: {
      items: [],
      paging: {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
      },
    },
    loadings: {
      videos: false,
      videosList: false,
      posts: false,
      postsList: false,
      reposts: false,
    },
  },

  getters: {},

  mutations: {
    SET_GROUP(state, group) {
      state.group = group
    },

    SET_GROUP_VIDEOS_STATS(state, stats) {
      state.groupVideosStats = stats
    },

    SET_GROUP_VIDEOS_UPLOADS_GRAPH(state, points) {
      state.groupVideosUploadsGraph = points
    },

    SET_GROUP_VIDEOS_VIEWS_GRAPH(state, points) {
      state.groupVideosViewsGraph = points
    },

    SET_VIDEOS(state, data) {
      state.videos.items = data.items || []
      state.videos.paging = data.paging || {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
      }
    },

    SET_LOADING_VIDEOS(state, data) {
      state.loadings.videos = data
    },

    SET_LOADING_VIDEOS_LIST(state, data) {
      if (state.loadings.videos) {
        return
      }
      state.loadings.videosList = data
    },

    SET_GROUP_POSTS_STATS(state, stats) {
      state.groupPostsStats = stats
    },

    SET_GROUP_POSTS_PUBLISH_GRAPH(state, points) {
      state.groupPostsPublishGraph = points
    },

    SET_GROUP_POSTS_TOTAL_REACH_GRAPH(state, points) {
      state.groupPostsTotalReachGraph = points
    },

    SET_POSTS(state, data) {
      state.posts.items = data.items || []
      state.posts.paging = data.paging || {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
      }
    },

    SET_LOADING_POSTS(state, data) {
      state.loadings.posts = data
    },

    SET_LOADING_POSTS_LIST(state, data) {
      if (state.loadings.posts) {
        return
      }
      state.loadings.postsList = data
    },

    SET_REPOSTS(state, data) {
      state.reposts.items = data.items || []
      state.reposts.paging = data.paging || {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
      }
    },

    SET_LOADING_REPOSTS(state, data) {
      state.loadings.reposts = data
    },
  },

  actions: {
    async syncGroup({commit}, id) {
      try {
        commit("SET_LOADING_VIDEOS", true)
        commit("SET_LOADING_POSTS", true)

        await syncGroup(id)
      } catch(e) {
        console.log(e)
      } finally {
        commit("SET_LOADING_VIDEOS", false)
        commit("SET_LOADING_POSTS", false)
      }
    },

    async getGroup({dispatch, commit}, {screenName, from, to}) {
      try {
        const group = await getGroup(screenName)
        commit("SET_GROUP", group)

        await dispatch("syncGroup", group.id)

        await Promise.all([
          dispatch("getVideosForPeriod", {from, to, filter: {}, sort: {}, paging: {}}),
          dispatch("getPostsForPeriod", {from, to, filter: {}, sort: {}, paging: {}, onlyWithVideo: false}),
        ])
      } catch (e) {
        console.log(e)
      }
    },

    async getVideosForPeriod({commit, state}, {from, to, filter, sort, paging}) {
      try {
        commit("SET_LOADING_VIDEOS", true)
        // Должен выполняться до остальных запросов!
        const stats = await getGroupVideosStatsForPeriod(state.group.id, from, to)
        commit("SET_GROUP_VIDEOS_STATS", stats)

        const [uploadGraph, viewsGraph, videos] = await Promise.all([
          getGroupVideosUploadsGraph(state.group.id, from, to),
          getGroupVideosViewsGraph(state.group.id, from, to),
          getGroupVideos(state.group.id, from, to, filter, sort, paging),
        ])
        commit("SET_GROUP_VIDEOS_UPLOADS_GRAPH", uploadGraph)
        commit("SET_GROUP_VIDEOS_VIEWS_GRAPH", viewsGraph)
        commit("SET_VIDEOS", videos)
      } catch (e) {
        console.log(e)
      } finally {
        commit("SET_LOADING_VIDEOS", false)
      }
    },

    async getVideos({commit, state}, {from, to, filter, sort, paging}) {
      try {
        commit("SET_LOADING_VIDEOS_LIST", true)

        const videos = await getGroupVideos(state.group.id, from, to, filter, sort, paging)
        commit("SET_VIDEOS", videos)
      } catch (e) {
        console.log(e)
      } finally {
        commit("SET_LOADING_VIDEOS_LIST", false)
      }
    },

    async getPostsForPeriod({commit, state}, {from, to, filter, sort, paging}) {
      try {
        commit("SET_LOADING_POSTS", true)
        // Должен выполняться до остальных запросов!
        const stats = await getGroupPostsStatsForPeriod(state.group.id, from, to, filter.onlyWithVideo || false)
        commit("SET_GROUP_POSTS_STATS", stats)

        const [publishGraph, totalReachGraph, posts] = await Promise.all([
          getGroupPostsPublishGraph(state.group.id, from, to, filter.onlyWithVideo || false),
          getGroupPostsTotalReachGraph(state.group.id, from, to, filter.onlyWithVideo || false),
          getGroupPosts(state.group.id, from, to, filter, sort, paging),
        ])
        commit("SET_GROUP_POSTS_PUBLISH_GRAPH", publishGraph)
        commit("SET_GROUP_POSTS_TOTAL_REACH_GRAPH", totalReachGraph)
        commit("SET_POSTS", posts)
      } catch (e) {
        console.log(e)
      } finally {
        commit("SET_LOADING_POSTS", false)
      }
    },

    async getPosts({commit, state}, {from, to, filter, sort, paging}) {
      try {
        commit("SET_LOADING_POSTS_LIST", true)

        const videos = await getGroupPosts(
            state.group.id,
            from,
            to,
            filter,
            sort,
            paging,
        )
        commit("SET_POSTS", videos)
      } catch (e) {
        console.log(e)
      } finally {
        commit("SET_LOADING_POSTS_LIST", false)
      }
    },

    async getReposts({commit, state}, {postId, sort, paging}) {
      try {
        commit("SET_LOADING_REPOSTS", true)

        const reposts = await getGroupPostReposts(state.group.id, postId, sort, paging)
        commit("SET_REPOSTS", reposts)

      } catch(e) {
        console.log(e)
      } finally {
        commit("SET_LOADING_REPOSTS", false)
      }
    },

    clearGroup({commit}) {
      commit("SET_GROUP", null)
    },
  }
}
