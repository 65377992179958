import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupPosts($id: Int!, $from: String!, $to: String!, $filter: VKPostCollectionFilter, $sort: VKPostCollectionSort, $paging: PagingInput) {
  vk {
    getGroupPosts(groupID: $id, params: {
      from: $from,
      to: $to,
      filter: $filter,
      sort: $sort,
      paging: $paging,
    }) {
      items {
        id
        title
        publishedAt
        counters {
          totalReach
          subscriberReach
          viralReach
          advertisingReach
          likes
          reposts
          comments
        }
        hasVideo
        isRepost
        link
      }
      paging {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
}`

async function getGroupPosts(id, from, to, filter, sort, paging) {
  return makeQuery({
    query,
    variables: { id, from, to, filter, sort, paging },
    success: data => data.vk.getGroupPosts,
  })
}

export default getGroupPosts
