import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
mutation SyncGroup($id: Int!) {
  vk {
    syncGroup(id: $id)
  }
}`

async function syncGroup(id) {
  return makeQuery({
    query,
    variables: { id },
  })
}

export default syncGroup
