import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupVideos($id: Int!, $from: String!, $to: String!, $filter: VKVideoCollectionFilter, $sort: VKVideoCollectionSort, $paging: PagingInput) {
  vk {
    getGroupVideos(groupID: $id, params: {
      from: $from,
      to: $to,
      filter: $filter,
      sort: $sort,
      paging: $paging,
    }) {
      items {
        id
        name
        description
        uploadedAt
        counters {
          views
          likes
          reposts
          comments
        }
        duration
        platform
        link
      }
      paging {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
}`

async function getGroupVideos(id, from, to, filter, sort, paging) {
  return makeQuery({
    query,
    variables: { id, from, to, filter, sort, paging },
    success: data => data.vk.getGroupVideos,
  })
}

export default getGroupVideos
