import axios from '@axios';
import { graphqlEndpoint } from '@/consts';

async function makeQuery({ query, variables, success }) {
  let response;
  try {
    const params = { query, variables }
    const call = await axios.post(graphqlEndpoint, params)
    response = call.data
  } catch (e) {
    response = e.response.data
  }

  const err = response.errors && response.errors[0]
  if (err) {
    throw err;
  }
  return success(response.data)
}

export { makeQuery }
