import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
mutation RefreshToken($refreshToken: String!) {
  users {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
}`

async function refreshToken(refreshToken) {
  return makeQuery({
    query,
    variables: { refreshToken },
    success: data => data.users.refreshToken,
  })
}

export default refreshToken
