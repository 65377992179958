import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupVideosStatsForPeriod($id: Int!, $from: String!, $to: String!) {
  vk {
    getGroupVideosStatsForPeriod(groupID: $id, from: $from, to: $to) {
      count
      views
      averageViews
      likes
      reposts
      comments
      duration
      averageDuration
    }
  }
}`

async function getGroupVideosStatsForPeriod(id, from, to) {
  return makeQuery({
    query,
    variables: { id, from, to },
    success: data => data.vk.getGroupVideosStatsForPeriod,
  })
}

export default getGroupVideosStatsForPeriod
