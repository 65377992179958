import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupPostsTotalReachGraph($id: Int!, $from: String!, $to: String!, $onlyWithVideo: Boolean!) {
  vk {
    getGroupPostsTotalReachGraph(groupID: $id, from: $from, to: $to, onlyWithVideo: $onlyWithVideo) {
      value
      date
    }
  }
}`

async function getGroupPostsTotalReachGraph(id, from, to, onlyWithVideo) {
  return makeQuery({
    query,
    variables: { id, from, to, onlyWithVideo },
    success: data => data.vk.getGroupPostsTotalReachGraph,
  })
}

export default getGroupPostsTotalReachGraph
