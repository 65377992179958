import getMe from '@/api/users/getMe'

export default {
  namespaced: true,

  state: {
    // Текущий аутентифицированный пользователь.
    current: null,
  },

  getters: {},

  mutations: {
    SAVE_CURRENT(state, user) {
      state.current = user
    },
  },

  actions: {
    async fetchCurrent({commit}) {
      return getMe()
        .then(user => {
          commit('SAVE_CURRENT', user)
          return user
        })
        .catch(e => console.log(e))
    },
  }
}
