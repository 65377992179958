import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupPostReposts($groupId: Int!, $postId: Int!, $sort: VKRepostCollectionSort, $paging: PagingInput) {
  vk {
    getGroupPostReposts(groupID: $groupId, postID: $postId, params: {
      sort: $sort
      paging: $paging
    }) {
      items {
        actorName
        repostedAt
        link
      }
      paging {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
}`

async function syncGroup(groupId, postId, sort, paging) {
  return makeQuery({
    query,
    variables: { groupId, postId, sort, paging},
    success: data => data.vk.getGroupPostReposts,
  })
}

export default syncGroup
