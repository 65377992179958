import { makeQuery } from '@/api/helpers/makeQuery'

const query = `query {
  users {
    getMe() {
      id
      email
      hasVKToken
    }
  }
}`

async function getMe() {
  return makeQuery({
    query,
    success: data => data.users.getMe,
  })
}

export default getMe
