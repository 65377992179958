import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroup($screenName: String!) {
  vk {
    getGroup(screenName: $screenName) {
      id
      title
      description
      link
      photoURL
      counters {
        members
        articles
        wallRecords
        videos
      }
    }
  }
}`

async function getGroup(screenName) {
  return makeQuery({
    query,
    variables: { screenName },
    success: data => data.vk.getGroup,
  })
}

export default getGroup
