import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupVideosUploadsGraph($id: Int!, $from: String!, $to: String!) {
  vk {
    getGroupVideosUploadsGraph(groupID: $id, from: $from, to: $to) {
      value
      date
    }
  }
}`

async function getGroupVideosUploadsGraph(id, from, to) {
  return makeQuery({
    query,
    variables: { id, from, to },
    success: data => data.vk.getGroupVideosUploadsGraph,
  })
}

export default getGroupVideosUploadsGraph
