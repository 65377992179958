import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupPostsStatsForPeriod($id: Int!, $from: String!, $to: String!, $onlyWithVideo: Boolean!) {
  vk {
    getGroupPostsStatsForPeriod(groupID: $id, from: $from, to: $to, onlyWithVideo: $onlyWithVideo) {
      count
      likes
      reposts
      comments
    }
  }
}`

async function getGroupPostsStatsForPeriod(id, from, to, onlyWithVideo) {
  return makeQuery({
    query,
    variables: { id, from, to, onlyWithVideo },
    success: data => data.vk.getGroupPostsStatsForPeriod,
  })
}

export default getGroupPostsStatsForPeriod
