import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
query GetGroupVideosViewsGraph($id: Int!, $from: String!, $to: String!) {
  vk {
    getGroupVideosViewsGraph(groupID: $id, from: $from, to: $to) {
      value
      date
    }
  }
}`

async function getGroupVideosViewsGraph(id, from, to) {
  return makeQuery({
    query,
    variables: { id, from, to },
    success: data => data.vk.getGroupVideosViewsGraph,
  })
}

export default getGroupVideosViewsGraph
